import logo from './logo.svg';
import './App.css';
import Main from './navigation/main';

function App() {
  return (
    <Main/>
      );
}

export default App;