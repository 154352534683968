import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import EventComponent from '../../Events/components/Card';
import { useNavigate } from 'react-router-dom';

// Generate Order Data



// const rows = [
//   createData(
//     0,
//     '16 Mar, 2019',
//     'Elvis Presley',
//     'Tupelo, MS',
//     'VISA ⠀•••• 3719',
//     312.44,
//   ),
//   createData(
//     1,
//     '16 Mar, 2019',
//     'Paul McCartney',
//     'London, UK',
//     'VISA ⠀•••• 2574',
//     866.99,
//   ),
//   createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
//   createData(
//     3,
//     '16 Mar, 2019',
//     'Michael Jackson',
//     'Gary, IN',
//     'AMEX ⠀•••• 2000',
//     654.39,
//   ),
//   createData(
//     4,
//     '15 Mar, 2019',
//     'Bruce Springsteen',
//     'Long Branch, NJ',
//     'VISA ⠀•••• 5919',
//     212.79,
//   ),
// ];



function preventDefault(event) {
  event.preventDefault();
}

export default function EventList() {
  const [events, setEvents] = React.useState(null)
  const navigate = useNavigate()


  React.useEffect(() => {
    const fetchUserData = async () => {
      const utoken = localStorage.getItem('utoken');
      if (utoken) {
        try {
          const response = await fetch(`https://vivum24.pythonanywhere.com/userdata?utoken=${utoken}`);
          const data = await response.json();
          setEvents(data.userdata.events)
          // data.map(val,index => {
          //   crea
          // })
          console.log("data for event fetch",data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.error('No utoken found in localStorage');
      }
    };

    fetchUserData();
  }, []);
  return (
    // <React.Fragment>
    //   <Title>Events</Title>
    //   <Table size="small">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell>Date</TableCell>
    //         <TableCell>Name</TableCell>
    //         <TableCell>Ship To</TableCell>
    //         <TableCell>Payment Method</TableCell>
    //         <TableCell align="right">Sale Amount</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {rows.map((row) => (
    //         <TableRow key={row.id}>
    //           <TableCell>{row.date}</TableCell>
    //           <TableCell>{row.name}</TableCell>
    //           <TableCell>{row.shipTo}</TableCell>
    //           <TableCell>{row.paymentMethod}</TableCell>
    //           <TableCell align="right">{`$${row.amount}`}</TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    //   <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
    //     See more Events
    //   </Link>
    // </React.Fragment>
    <EventComponent events={events} navigate={navigate}/>
  );
}
